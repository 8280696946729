import '@toasttab/main/src/assets/styles/global.css'
import 'lazysizes'
import React from 'react'
import RootElement from './src/components/root-element'
// import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { wrapPageElement as SharedWrapPageElement } from '@toasttab/shared/src/page/wrapPageElement'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import { sha256encode } from '@toasttab/shared/src/utils/dataCaptureUtils'
import _helpers from '@toasttab/shared/src/globals/_helpers'

export const wrapRootElement = ({ element }: any) => {
  const enableLiveUpdates =
    process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_ENABLE_LIVE_UPDATES || true
  const enableInspectorMode =
    process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_ENABLE_INSPECTOR_MODE || true
  const locale =
    process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_DEFAULT_LOCALE || 'en'
  const debugMode =
    process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_DEBUG_MODE || true

  const App = () => (
    <ContentfulLivePreviewProvider
      locale={String(locale)}
      enableInspectorMode={Boolean(enableInspectorMode)}
      enableLiveUpdates={Boolean(enableLiveUpdates)}
      debugMode={Boolean(debugMode)}
    >
      <RootElement>{element}</RootElement>
    </ContentfulLivePreviewProvider>
  )
  // const LDApp = withLDProvider({ clientSideID: '64384aba5a7cac13c8150bf8' })(App)
  return <App />
}

export const wrapPageElement = SharedWrapPageElement

export const onClientEntry = () => {
  if (typeof window === 'undefined') return // Ensure this runs only in the browser

  window.dataLayer = window.dataLayer || []

  const initGTMOnEvent = (event: any) => {
    console.log('initGTMOnEvent')
    initGTM()
    event.currentTarget.removeEventListener(event.type, initGTMOnEvent) // Remove the triggered event listener
  }

  const initGTM = () => {
    if (window.gtmDidInit) {
      return false // Ensure the script is not added multiple times
    }
    window.gtmDidInit = true

    // Create and append the GTM script
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-MNHK89W'

    script.onload = () => {
      window.dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
        'gtm.uniqueEventId': 0
      }) // Ensure PageViews are tracked
    }

    // MKE-3149: Add SHA256 hashed email and phone number to dataLayer for GTM tracking pixel
    if (_helpers.getCookie('email') || _helpers.getCookie('phonenumber')) {
      Promise.all([
        sha256encode(_helpers.getCookie('email')),
        sha256encode(_helpers.getCookie('phonenumber'))
      ]).then(([encodedEmail, encodedPhone]) => {
        window.dataLayer.push({
          user_data: {
            sha256_email_address: encodedEmail,
            sha256_phone_number: encodedPhone
          }
        })
      })
    }

    document.head.appendChild(script)
  }

  // Add event listeners
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(initGTM, 3500) // Initialize GTM after 3500ms
  })

  document.addEventListener('scroll', initGTMOnEvent)
  document.addEventListener('mousemove', initGTMOnEvent)
  document.addEventListener('touchstart', initGTMOnEvent)
}
