export const extractFirstNameLastName = (fullname: string) => {
  if (fullname.indexOf(' ') > 0) {
    let FirstName = fullname.substring(0, fullname.indexOf(' '))
    let LastName = fullname.substring(fullname.indexOf(' ') + 1)
    return { FirstName: FirstName, LastName: LastName, FullName: fullname }
  } else {
    return { FirstName: fullname, LastName: null, FullName: fullname }
  }
}

export const sha256encode = async (message: any) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  return hashHex
}
